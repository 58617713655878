.Candidates {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-column,
.right-column {
  flex: 1; /* Each column takes up 50% of the width */
  padding: 20px;
}

.right-column {
  background-color: #f4f4f4; /* Light gray background */
  border-radius: 8px;
  text-align: left;
}

.candidate-table {
  width: 100%;
  border-collapse: collapse;
}

.candidate-header {
  display: flex;
  justify-content: space-between;
  background-color: #007bff;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

.header-item {
  flex: 1;
  text-align: left;
}

.candidate-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.candidate-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.3s;
  cursor: pointer;
}

.candidate-item:hover {
  background-color: #f0f8ff; /* Light blue background on hover */
}

.candidate-info {
  flex: 1;
  text-align: left;
}

.candidate-info.clickable {
  color: #007bff;
  cursor: pointer;
}

.candidate-info.clickable:hover {
  text-decoration: underline;
}

.candidate-item a {
  color: #007bff;
  text-decoration: none;
}

.candidate-item a:hover {
  text-decoration: underline;
}

.results-box {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.action-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.action-button:hover {
  background-color: #0056b3;
}
