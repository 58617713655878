.Add-Job {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Add-Job h1 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Arial, sans-serif; /* Ensures both input and textarea use the same font */
  font-size: 14px; /* Optional: You can also set a font size if needed */
}

.form-group textarea {
  height: 100px; /* Set a height for the textarea */
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.form-group input[type="file"] {
  width: calc(100% - 100px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
}

.custom-multi-select {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr auto; /* 1fr for text, auto for radio button */
  row-gap: 10px; /* Space between rows */
  column-gap: 20px; /* Space between text and radio button */
}

.custom-multi-select label {
  display: contents; /* Makes label act as a container for grid items */
}

.custom-multi-select label span {
  grid-column: 1; /* Text in the first column */
}

.custom-multi-select input[type="radio"] {
  grid-column: 2; /* Radio button in the second column */
  justify-self: end; /* Align radio button to the right */
}
