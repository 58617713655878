.Search-Candidate {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-column {
  width: 40%;
}

.right-column {
  width: 55%;
}

.candidate-table {
  margin-top: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.candidate-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.header-item {
  width: 33%;
  text-align: center;
}

.candidate-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.candidate-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.candidate-info {
  width: 33%;
  text-align: center;
}

.candidate-info a {
  color: #007bff;
  text-decoration: none;
}

.candidate-info a:hover {
  text-decoration: underline;
}

.candidate-details {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.results-box {
  margin-top: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.action-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}
