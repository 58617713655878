.hero-section {
  text-align: center;
  margin: 20px 0;
}

.content-section {
  background-color: #f2f2f2; /* Light grey background */
  padding: 20px;
  display: flex;
  flex-direction: column; /* Change to column to stack content and button vertically */
  align-items: center; /* Center align all items */
}

.features-instructions-container {
  display: flex;
  width: 80%; /* Adjust as needed */
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
}

.features-box,
.instructions-box {
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.features-box {
  margin-right: 20px;
}

.instructions-box {
  margin-left: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

ul, ol {
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

/* Adjust the button container */
.button-container {
  margin-top: 30px; /* Add some space between the content and the button */
}

.cta-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}
