.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  header {
    background-color: #333;
    color: white;
    padding: 10px 20px;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }
  
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  