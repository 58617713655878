.footer {
    padding: 1rem;
    background-color: #333;
    color: white;
    text-align: center;
  }
  
  .footer-links a {
    color: white;
    margin: 0 0.5rem;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  