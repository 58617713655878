.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a2a50; /* Dark blue background color */
  padding: 10px 20px;
  font-family: Arial, sans-serif;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  color: white;
  font-size: 20px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links .dropdown {
  position: relative;
  margin-left: 20px;
}

.nav-links a {
  color: white;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
}

.nav-links a:hover {
  color: #007bff; /* Blue color on hover */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 180px; /* Adjusted width */
  border-radius: 5px;
  right: 0; /* Align to the right to prevent overflow */
  padding-right: 10px; /* Add padding to the right */
}

.dropdown-content a {
  color: black;
  padding: 10px 14px; /* Adjusted padding */
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:hover {
  color: #007bff; /* Blue color on hover */
  background-color: transparent; /* No background color on hover */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover > a {
  color: #007bff; /* Change the color of the main link to blue on hover */
}

.nav-links .dropdown > a::after {
  content: " \25BC"; /* Downward arrow */
  font-size: 12px;
  padding-left: 5px;
}

.dropdown-content-right {
  right: 0;
  left: auto;
}
